import React, { useEffect, useState } from 'react';
import ScreenJs from '@/utils/screen';
import { Modal, Button} from 'antd';

import Api from '@/api';
import Utils from '@/utils/utils';

import './PrintMain.scss';

const PrintMain = (props) => {

  const printNow = () => {
    window.print();
  }
  const [enrollRow, setEnrollRow] = useState({});
  const [downloadUrl, setDownloadUrl] = useState('');
  const [pdfImage,setPdfImage] = useState('');
  const [isIOS,setIsIOS] = useState(false);
  const [downloadName,setDownloadName] = useState('');
  const [downloadPdfUrl,setDownloadPdfUrl] = useState('');
  const [isFinish,setIsFinish] = useState(false);

  let pdf = null;
  //生产pdf
  const pdfCreator = () => {
    if(downloadPdfUrl){
      downloadNow();
      return;
    }
    const tableDom = document.querySelector('#printContentPdf');
    Utils.message('正在生成pdf，请稍等' );
    window.html2canvas(tableDom, {
      allowTanint: true,
      scale:1,
      scrollX: 0,
      scrollY: 0,
      backgroundColor:'#F000',
      useCORS:true,
    }).then(function (canvas) {
      pdf = new window.jsPDF('p', 'pt', 'a4');
      let { width, height } = canvas;
      const a4Width = 592.28;
      let imgWidth = a4Width;
      let imgHeight = a4Width/width * height;
      let pageData = canvas.toDataURL('image/jpeg',1.0);
      setPdfImage(pageData);
      pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
      setDownloadName(enrollRow.bookName + '.pdf');
      const blob = pdf.output('blob');
      const file= new window.File(
        [blob],
        enrollRow.bookName + '.pdf',
        {type: blob.type}
      );
      let formData = new FormData();
      formData.append('file', file, encodeURIComponent(enrollRow.bookName + '.pdf'));
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)) {
        if(!/ipad|iphone/.test(window.navigator.userAgent.toLowerCase())) {
          Api.resourceUpload('pdf', formData).then(res=>{
            res && res.length > 0 && setDownloadPdfUrl(res[0].fileUrl);
            downloadNow();
          }).catch(err => {
          });
        }else{
          downloadNow();
        }
      }else{
        downloadNow();
      }
      
    });
  
  }

  const downloadNow = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)) {
      if(!/ipad|iphone/.test(window.navigator.userAgent.toLowerCase())) {
        setIsFinish(true);
      }else{
        setIsIOS(true);
      }
    }else{
      pdf.save(enrollRow.bookName + '.pdf');
    }
  }

  const createTableDom = (isForPdf) => {
    return <div className={`print-content ${isForPdf ? 'print-for-pdf ' : ''}`} id={isForPdf ? "printContentPdf" : "printContent"}>
      <h1>出版融合技术·编辑创新大赛报名表</h1>
      <ul>
        {TABLELIST.map((cols, colIndex) => <li key={colIndex}>
          {cols.map((row, rowIndex) => <dl key={rowIndex} style={{ flex: row.width ? ('0 0 ' + row.width + 'px') : '' }}>
            <dt>{row.label}</dt>
            <dd style={{ height: row.height ? row.height + 'px' : '' }}>{enrollRow[row.name]}</dd>
          </dl>)}
        </li>)}
      </ul>
      <p>加盖单位公章处</p>

    </div>
  }

  useEffect(() => {
    const id = Utils.urlQuery()['id'];
    Api.enrollDetail({ id }).then(res => {
      setEnrollRow(res);
      //pdfCreator(res);
    });
    Api.downloadEnroll({ id, searchContent: '', auditStatus: 0, sourceType: 0 }).then(res => {
      setDownloadUrl(res.fileUrl);
    }).catch(err => {
    });
    ScreenJs();
    window.addEventListener('resize',e=>{
      ScreenJs();
    })
  }, [])


  const tableDom = createTableDom();
  const tableDomPdf = createTableDom(true);

  return <div className="PrintMain">
    <div className="print-tools">
      <a className="iconfont white-hover"  onClick={()=>pdfCreator()}>&#xe683;</a>
      {/* <a className="iconfont white-hover" href={downloadUrl} download>&#xe683;<i>EXCEL</i></a> */}
      <a className="iconfont white-hover" onClick={e => printNow()} >&#xe6bb;</a>
    </div>
    {tableDom}
    {tableDomPdf}
    <Modal visible={isIOS} footer={[<Button onClick={() => setIsIOS(false)} onClose={() => setIsIOS(false) }>关闭</Button>]}  closable={false} >
      <div className="pdf-modal">
        <p className="pdf-title">IOS系统不支持pdf下载，您可以长按下方区域保存为图片</p>
        <img src={pdfImage} className="pdf-image" />
      </div>
    </Modal>

    <Modal visible={isFinish} footer={[<Button onClick={() => {window.open(downloadPdfUrl);setIsFinish(false)}}>下载</Button>, <Button onClick={() => setIsFinish(false)} onClose={() => setIsIOS(false) }>关闭</Button>]}  closable={false} >
      <div className="pdf-modal">
        <p className="pdf-title">pdf生成成功，您可以点击下载按钮进行下载，由于手机权限设置，部分机型可能无法下载，您可以选择长按下方区域保存为图片</p>
        <img src={pdfImage} className="pdf-image" />
      </div>
    </Modal>
  </div>
}

export default PrintMain;


const TABLELIST = [
  [
    { label: '工作单位', value: '', height: '', name: 'companyName' },
    { label: '姓名', value: '', height: '', name: 'contestantName' },
    { label: '团队其他成员姓名', value: '', height: '', name: 'teamMemberName' },
    { label: '手机号码', value: '', width: '', height: '', name: 'phone' }
  ],
  [
    { label: '出版物名称', value: '', height: '', name: 'bookName' },
    //{label:'ISBN',value:'',width:'60',height:'',name:'isbn'},
    //{label:'出版物类型',value:'',width:'',height:'',name:'bookType'},
    { label: '印刷时间', value: '', width: '', height: '', name: 'printDate' },
    { label: '发行量', value: '', width: '', height: '', name: 'publishNum' },
    { label: '微信号', value: '', width: '', height: '', name: 'wechatNo' }
  ],
  [
    { label: 'RAYS编辑端账号', value: '', width: '', height: '', name: 'adviserAccount' },
    { label: '电子邮箱', value: '', width: '', height: '', name: 'email' },
    { label: '身份证号', value: '', width: '350', height: '', name: 'idCard' }
  ],

  [
    { label: '通讯地址', value: '', width: '', height: '', name: 'address' },
    { label: '注册责任编辑/出版职业资格证书编号', value: '', height: '', name: 'adviserCertificateNo' },
  ],



  // [
  //   {label:'出版物简介',value:'',width:'',height:'205',name:'bookIntroduction'},
  // ],

  // [
  //   {label:'作者及编辑简介',value:'',width:'',height:'205',name:'adviserIntroduction'},
  // ],
]