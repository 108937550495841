
export default ()=>{
  const head=document.getElementsByTagName('head')[0];
  const minWidth=1366;
  let viewport;//=document.createElement('meta');
  [].slice.call(head.children).map(item=>{
    if(item.name=='viewport'){
      viewport=item;
    }
  })

  {
    const width = document.documentElement.clientWidth,height = document.documentElement.clientHeight;
    if(!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent.toLowerCase()) && width > 1000){
      document.getElementsByTagName('html')[0].style.fontSize = '';
      let footer = document.getElementsByTagName('footer');
      // console.log(footer)
      if(width < minWidth){
        document.getElementById('___gatsby').style.transform='scale('+ width / minWidth+')';
        let minHeight = (2 - width/minWidth) * height;
        minHeight = minHeight < 1050 ? 1050 : minHeight;
        document.getElementsByTagName('body')[0].style.minHeight = minHeight + 'px';
  
        
        const moreHeight = (1 - width/minWidth) * height;
        if(footer.length) footer[0].style.bottom = - (1 - width/minWidth) * width + 'px';
      }else{
        document.getElementById('___gatsby').style.transform='';
        document.getElementsByTagName('body')[0].style.minHeight='';
        if(footer.length) footer[0].style.bottom = '0';
      }
      return;
    }else{
      document.getElementById('___gatsby').style.transform='';
      document.getElementsByTagName('body')[0].style.minHeight='';
      const width = document.documentElement.clientWidth;
      if(width < 1000){
        document.getElementsByTagName('html')[0].style.fontSize = 100 * (width / 750) + 'px';
        return;
      }else{
        document.getElementsByTagName('html')[0].style.fontSize = '';
      }
    }
  }
  
  let scale=1;
  
  viewport.name='viewport';

  scale=1;
  let oldScale=0;
  if(viewport.content.indexOf('initial-scale')>-1)
    oldScale = viewport.content.substring(viewport.content.indexOf('initial-scale')+13).replace('=','')*1;
  if(oldScale && oldScale!=NaN)
    scale=oldScale;

  const width=document.documentElement.clientWidth*scale;
  if(width<minWidth){
    scale=width/minWidth;
  }else if(width==minWidth){
    return;
  }else{
    scale=1;
  }
  scale=scale.toFixed(2);

  if(scale!=oldScale)
    viewport.content='target-densitydpi=device-dpi, width=device-width, user-scalable=no, initial-scale='+scale;
  
  return null;
}